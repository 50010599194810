<template>
  <div class="container">
    <!-- 智库详情 -->
    <div class="medicine-detail">
      <div class="header">
        <p class="title">{{info.title}}</p>
        <div class="time">
          <span>{{info.create_time}}</span>
          <span>{{info.view_num}}浏览</span>
        </div>
      </div>

      <!-- 内容介绍 -->
      <div class="content-intro">
        <img :src="info.cover" alt="">
        <div class="rich-text" v-html="info.content"></div>
      </div>

      <!-- 全部评价 -->
      <div class="appraise-full-box">
        <div class="content-appraise">
          <div class="appraise-header">
            <p>最新评论（{{info.comment_count}}）</p>
          </div>
          <van-empty description="暂无评论" v-if="commentList.length == 0" />
          <div v-if="commentList.length > 0">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onRefresh"
            >
              <div class="appraise-item" v-for="(item, index) in commentList" :key="index">
                <img class="appraise-user" :src="item.avatarurl">
                <div class="appraise-detail">
                  <div class="appraise-detail-span">
                    <span>{{item.nickname}}</span>
                    <span>{{item.timeShow}}</span>
                  </div>
                  <p>{{item.content}}</p>
                </div>
              </div>
            </van-list>
          </div>
        </div>
        <div class="comment-input">
          <input placeholder="请输入评价内容" maxlength="200" @input="changeValue('content')"
                 v-model.trim="formData.content"
          />
          <p class="btn" @click="createComment()">发送</p>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {Dialog} from "vant";

export default {
  data() {
    return {
      show: false,
      showLoading: false,
      info: {
        comment_count: 0 
      },
      commentList: [],
      formData: {
        'id': 0,
        'action': 'article',
        'content': '',
        'offset': 0,
        'limit': 10,
      },
      userinfo: {},
      word: '数据加载中...',
      loading: false,
      finished: false,
      refreshStatus: false
    }
  },
  created() {
    const id = this.$untils.getUrlKey('id');
    this.formData.id = id;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.getInfo(id);
    this.getComment();
  },
  methods: {
    onRefresh() {
      this.getComment()
    },
    //获取评论
    getComment() {
      let that = this
      if (that.refreshStatus) {
        return false
      }
      if (that.formData.offset == 0) {
        that.commentList = []
      }
      that.refreshStatus = true
      this.$api.LIST_COMMENT_POST(that.formData).then(res => {
        if (that.formData.offset > 0 && res.length == 0) {
          that.refreshStatus = false
          that.finished = true
        }
        if (res.length === 0) {
          that.refreshStatus = false
          that.word = '暂无数据'
          return false
        }
        res.forEach((item) => {
          that.commentList.push({
            id: item.id,
            nickname: item.nickname,
            content: item.content,
            avatarurl: item.avatarurl ? item.avatarurl : '',
            timeShow: item.create_time
          })
        })
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        } else {
          that.finished = true
        }
        that.loading =false
        that.refreshStatus = false
      })
    },
    //提交评论
    createComment() {
      let that = this
      if (that.showLoading == true) {
        return false
      }
      if (!that.formData.content) {
        Dialog({message: "请输入评论内容"})
        return
      }
      that.showLoading = true
      // console.log(that.formData, '提交参数')
      this.$api.CREATE_COMMENT_POST(that.formData).then(res => {
        if (!res.code) {
          that.commentList.unshift({'id': res.id, 'content': that.formData.content, 'nickname': that.userinfo.username, 'avatarurl': that.userinfo.avatarUrl, 'timeShow': res.create_time})
          that.info.comment_count++
        }
        Dialog({ message: res.msg });
        that.formData.content = ''
        that.showLoading = false
      })
    },
    //文本验证
    changeValue() {
      let that = this
      let value = that.formData.content; //校验的字段
      // 中文、中文标点、全角字符按1长度，英文、英文符号、数字按0.5长度计算
      let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/;
      let maxLimitStr = 200; //25个汉字
      let pass = true;
      let substringStr = "";
      let length = 0;
      let strArr = value.split("");
      strArr.map((str) => {
        if (pass) {
          if (cnReg.test(str)) {//中文
            length++;
          } else {//英文
            length += 0.5;
          }
          //大于最大限制的时候
          if (length > maxLimitStr) {
            pass = false;
            Dialog({message: "文字长度已超出最大值，最大值为" + maxLimitStr * 2 + "个字符"})
            //将校验的字符截取返回
            that.formData.content = substringStr;
          } else {
            substringStr += str;
          }
        }
      });
      return pass;
    },
    //获取详情
    getInfo(id) {
      let that = this
      this.$api.ARTICLE_GET({
        'id': id
      }).then(res => {
        that.info = res
      })
    }
  }
}
</script>
<style lang="less" scoped>
.medicine-detail{
  .header{
    margin-bottom:20px;
    .title{
      font-size:16px;
      color:#000;
      padding:8px 0 6px 0;
    }
    .time{
      display:flex;
      align-items: center;
      justify-content: space-between;
      font-size:12px;
      color:#707070;
    }
  }

  .content-intro{
    margin-bottom:18px;
    img{
      display:block;
      width:100%;
      max-height:100%;
    }
  }
}


</style>